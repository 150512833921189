import { React } from "react";

export function Photoshop(props) {

    // const handleClick = (event) => {
    //     console.log("you clicked me!");
    // }
    return (
        <div>
            <main>
                <div className="boundary">

                    <div className="container gallery">
                        <h2 className="photoshop-title">PHOTOSHOP POSTERS</h2>
                        <div className="row gallery">
                            <div className="col gallery">
                                <img src="img/photoshop/photoshop12.jpg" alt="gallery Photoshop" className="gallery" />
                            </div>
                            <div className="col gallery">
                                <img src="img/photoshop/photoshop14.jpg" alt="gallery Photoshop" className="gallery" />
                            </div>
                            <div className="col gallery">
                                <img src="img/photoshop/photoshop13.jpg" alt="gallery Photoshop" className="gallery" />
                            </div>
                            <div className="col gallery">
                                <img src="img/photoshop/photoshop15.jpg" alt="gallery Photoshop" className="gallery" />
                            </div>
                        </div>

                        <div className="row gallery">
                            <h2 className="photoshop-title">PHOTOSHOP EDITS</h2>
                            <div className="col gallery bg-image hover-zoom">
                                <img src="img/photoshop/photoshop1.jpg" alt="gallery Photoshop" className="gallery" />
                                <img src="img/photoshop/photoshop2.png" alt="gallery Photoshop" className="gallery" />
                            </div>
                            <div className="col gallery bg-image hover-zoom">
                                <img src="img/photoshop/photoshop3.jpg" alt="gallery Photoshop" className="gallery" />
                            </div>
                        </div>


                        <div className="row gallery">
                            <h2 className="photoshop-title">COLLAGES</h2>
                            <div className="col gallery bg-image hover-zoom">
                                <img src="img/photoshop/photoshop4.PNG" alt="gallery Photoshop" className="gallery" />
                                <img src="img/photoshop/photoshop5.PNG" alt="gallery Photoshop" className="gallery" />
                                <img src="img/photoshop/photoshop9.PNG" alt="gallery Photoshop" className="gallery" />

                            </div>
                            <div className="col gallery bg-image hover-zoom">
                                <img src="img/photoshop/photoshop6.PNG" alt="gallery Photoshop" className="gallery" />
                                <img src="img/photoshop/photoshop7.PNG" alt="gallery Photoshop" className="gallery" />

                            </div>
                            <div className="col gallery bg-image hover-zoom">
                                <img src="img/photoshop/photoshop11.PNG" alt="gallery Photoshop" className="gallery" />
                                <img src="img/photoshop/photoshop10.PNG" alt="gallery Photoshop" className="gallery" />
                                <img src="img/photoshop/photoshop8.PNG" alt="gallery Photoshop" className="gallery" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
