import React from "react";

export function Resume(props) {
    return (
        <main>
            <div className="boundary">
                <section id="education">
                    <p className="title-label text">EDUCATION</p>

                    <div className="row education">

                        <div className="col education">
                            <div className="row">
                                <p className="regular-text udub education-text-title">University of Washington, Seattle</p>
                                <p className="regular-text ischool">Information School</p>
                            </div>
                            <div className="row">
                                <p className="label education-text-title">GPA</p>
                                <p className="regular-text ml-2 gpa">3.80 / 4.0</p>
                            </div>
                            <div className="row">
                                <p className="label education-text-title">Graduation Date</p>
                                <p className="regular-text ml-2 grad">June 2023</p>
                            </div>
                            <div className="row">
                                <p className="label education-text-title">Awards</p>
                                <p className="regular-text ml-2 award">Dean's List: 2020 - 2023</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="certification">
                    <p className="title-label text">CERTIFICATIONS</p>

                    <div className="row certification">

                        <div className="col certification">
                            <div className="row">
                                <p className="regular-text">Professional Scrum Master I</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="experience">

                    <p className="title-label text">EXPERIENCE</p>

                    <div className="row experience">
                        <div id="exp" className="col-4 text">
                            <p className="label role col-label-space">Project Management Intern</p>
                            <p className="label company col-label-space">Impinj, Inc.</p>
                            <p className="regular-text date col-label-space">Summer 2023</p>
                        </div>
                        <div className="col text">
                            <p className="description regular-text">
                                Led a dynamic transformation for the Development Infrastructure (DI) Team, initiating the development of a comprehensive service catalog to enhance communication and transparency of provided services, along with their associated standards, processes, methodologies, and applications.
                            </p>
                            <ul className="description bullet-points regular-text">
                                <li>Researched and integrated diverse service approaches, emphasizing ITIL alignment</li>
                                <li>Created epics and user stories to align with team and strategic goals</li>
                                <li>Established robust web presence, integrating newly developed service catalog</li>
                                <li>Devised vendor and applications catalog, streamlining support as a foundational service level agreement</li>
                                <li>Collaborated with cross-functional IT teams, ensuring harmony in catalog development and larger initiatives</li>
                                <li>Acquired Scrum training and certification, effectively leading daily meetings, fostering momentum, and steering team success through agile principles</li>
                            </ul>
                        </div>
                    </div>

                    <hr />

                    <div className="row experience">
                        <div id="exp" className="col-4 text">
                            <p className="label role col-label-space">Business Intelligence Analyst Intern</p>
                            <p className="label company col-label-space">Moss Adams</p>
                            <p className="regular-text date col-label-space">Summer 2022</p>
                        </div>
                        <div className="col text">
                            <p className="description regular-text">
                                Conducted a client-facing project that analyzed power utilities of a specific region to
                                identify if service order tickets are being processed equitably throughout the demographic.
                            </p>
                            <ul className="description bullet-points regular-text">
                                <li>Gathered and wrangled data using Tableau Prep to only include information that was
                                    valuable
                                    and applicable to the project</li>
                                <li>Utilized Tableau Desktop to produce calculations and create data visualizations to draw
                                    conclusions</li>
                                <li>Produced and presented a final deliverable describing the data processes and developed
                                    visualizations to the client</li>
                                <li>Effectively communicated and attended daily team meetings</li>
                            </ul>
                        </div>
                    </div>

                    <hr />

                    <div className="row experience">
                        <div id="exp" className="col-4 text">
                            <p className="label role col-label-space">Virtual Reality Intern</p>
                            <p className="label company col-label-space">Seattle Public Library</p>
                            <p className="regular-text date col-label-space">Summer 2021</p>
                            <a
                                href="https://www.spl.org/about-us/news-releases/imls-grants-the-seattle-public-library-funds-for-teen-mental-health-in-virtual-reality">Learn more</a>
                        </div>
                        <div className="col text">
                            <p className="description regular-text">
                                Successfully assisted a developer on a project that aimed to create a virtual environment to
                                combat the mental health and wellness effects of COVID-19 on teens aged thirteen to
                                eighteen.
                            </p>
                            <ul className="description bullet-points regular-text">
                                <li>Facilitated bi-weekly design sprint meetings to gather information on what teenagers
                                    would
                                    like to see through VR</li>
                                <li>Navigated the platform Unity and created virtual environments from scratch</li>
                                <li>Managed communication between project lead and participants</li>
                            </ul>
                        </div>
                    </div>

                    <hr />

                    <div className="row experience">
                        <div id="exp" className="col-4 text">
                            <p className="label role col-label-space">Website Manager</p>
                            <p className="label company col-label-space">Gamma Phi Beta</p>
                            <p className="regular-text date col-label-space">Dec 2020 – Dec 2021</p>
                            <a className="label link" href="https://www.gammaphibetauw.com/">Link</a>
                        </div>
                        <div className="col text">
                            <p className="description regular-text">
                                Accomplished building and designing a comprehensive and accessible interface for my chapter
                                using Webflow.
                            </p>
                            <ul className="description bullet-points regular-text">
                                <li>Frequently asked users to test the newly updated website and reflect upon their
                                    experience
                                </li>
                                <li>Maintained Gamma Phi Beta’s website</li>
                            </ul>
                        </div>
                    </div>

                    <hr />

                    <div className="row experience">
                        <div id="exp" className="col-4 text">
                            <p className="label role col-label-space">Educator</p>
                            <p className="label company col-label-space">Lululemon</p>
                            <p className="regular-text date col-label-space">Jul 2021 – Aug 2022</p>
                        </div>
                        <div className="col text">
                            <p className="description regular-text">
                                Properly communicate with managers, co-employees, and customers to provide best client
                                service.
                            </p>
                            <ul className="description bullet-points regular-text">
                                <li>Educate customers on design aesthetics and functions of items</li>
                                <li>Provide friendly customer service as a cashier and on the floor</li>
                                <li>Work alongside co-employees to provide best possible service</li>
                                <li>Train and mentor new educators</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="skills">
                    <p className="title-label text">SKILLS</p>
                    <div className="row skill">
                        <ul className="skill-list">
                            <span className="specific-skill badge">Python</span>
                            <span className="specific-skill badge">Java</span>
                            <span className="specific-skill badge">R</span>
                            <span className="specific-skill badge">SQL</span>
                            <span className="specific-skill badge">Scrum</span>
                            <span className="specific-skill badge">Agile</span>
                            <span className="specific-skill badge">ITIL</span>
                            <span className="specific-skill badge">Jira</span>
                            <span className="specific-skill badge">Confluence</span>
                            <span className="specific-skill badge">Microsoft</span>
                            <span className="specific-skill badge">Front End Development</span>
                            <span className="specific-skill badge">Mobile Development</span>
                            <span className="specific-skill badge">Tableau</span>
                            <span className="specific-skill badge">Unity</span>
                            <span className="specific-skill badge">Figma</span>
                            <span className="specific-skill badge">GitHub</span>
                            <span className="specific-skill badge">Trilingual (Spanish, French, English)</span>
                        </ul>
                    </div>
                </section>

                <section id="projects">
                    <p className="title-label text">PROJECTS</p>
                    <div className="row project">
                        <p className="label name">Currently compiling my work to publish on my site! Thank you for your patience.</p>
                    </div>
                </section>
            </div>

        </main>
    )
}
