import React from "react";

export function Footer(props) {

    return (
        <footer className="app-footer">
            <div id="page-container">
                <p id="connect" className="malena-arduino-footer">CONNECT</p>
                <a id="connect" className="connect" href="mailto: malenaarduino@hotmail.com">malenaarduino@hotmail.com</a> |
                <a id="connect" className="connect" href="tel:4256239622">4256239622</a> |
                <a id="connect" className="connect" href="https://www.linkedin.com/in/malenaarduino/">linkedin.com/ln/malenaarduino</a>
                <p id="connect" className="malena-arduino-footer">© 2023 MALENA ARDUINO ©</p>

            </div>
        </footer>
    )
}