import React from "react";
import { NavLink } from "react-router-dom";

export function Header(props) {
    return (
        <div className="header fixed-top" id="myHeader">
            <header>
                <h2 id="header-name">MALENA ARDUINO</h2>
            </header>
            <nav id="nav" className="topnav navbar-fixed-top">
                <NavLink to="/" activeclassname="active">About</NavLink>
                <NavLink to="/resume" activeclassname="active">Resume</NavLink>
                <NavLink to="/interests" activeclassname="active">Interests</NavLink>
            </nav>
        </div>
    )
}



