import React from "react";
import { useNavigate } from "react-router-dom";
// import { Gallery } from "./Gallery";


export function Interests(props) {

    let navigate = useNavigate(); 
    const routeChangeGallery = () =>{ 
        let path = '../gallery'
      navigate(path);
    }

    // const routeChangePhotoshop = () =>{ 
    //     let path = '../photoshop'
    //   navigate(path);
    // }

    return (
        <main>

            <div className="boundary">

                <h2>FURTHER INTO MY PERSONAL LIFE</h2>
                <hr />
                <p className="interests-top-description">
                    Engaging in hobbies and interests is essential for maintaining a healthy work-life balance. Whenever I am not studying or doing school-related work, I like to pursue various leisure activities that provide me with relaxation and personal fulfillment. Some of my favorite hobbies and interests include spending time in nature, exercising, sketching, listening to music, reading, crocheting, and socializing with family and friends! Engaging in these activities helps me alleviate stress, enhance my creativity, and provide me with a sense of achievement. By incorporating these hobbies and interests into my life, I am able to improve my overall well-being and productivity in both my academic and professional endeavors.
                </p>

                <div className="area">
                    <div className="row interest">
                        <div id="interest" className="text col img-wrapper">
                            <h2>NATURE</h2>
                            <hr />
                            <p className="interests-nature">
                                Spending time outdoors is one of my favorite ways to unwind and connect with the world around me. There's something about being in nature that reminds me of the beauty in imperfection, and how everything can be imperfectly perfect. Whether I'm hiking through a scenic trail or walking along the street, I find a sense of peace and grounding that allows me to appreciate the imperfections of the world. Engaging with the natural world in this way helps me to recognize that growth and progress can happen in imperfect and unexpected ways. It's a reminder that we don't have to strive for perfection in order to experience beauty and joy. Ultimately, spending time outdoors is an essential part of my life that helps me to appreciate and embrace the imperfections of the world around me.
                            </p>
                        </div>


                        <img src="img/hike2.jpg" alt="Hike Lookout" className="col-6 img-fluid img-responsive blur" />
                    </div>

                    <div className="row interest">
                        <div id="interest" className="text col">
                            <h2>PHOTOTGRAPHY</h2>
                            <hr />
                            <p className="text interests-nature">
                                Photography allows me to capture and freeze moments in time that are special and meaningful to me. Its powerful art form enables me to express myself and convey my perspective of the world around me. Through photography, I am able to tell stories, evoke emotions, and share experiences with others. I enjoy the technical aspects of photography, experimenting with lighting, composition, and camera settings to create the perfect shot. Photography also allows me to explore and discover new places and people, to see the beauty and uniqueness of the world in a way that I may not have noticed before.
                            </p>
                            <button className="btn photography-gallery" onClick={routeChangeGallery}>View Gallery</button>
                        </div>
                        <img src="img/gallery55.jpg" alt="Taking pictures" className="col-6 img-fluid img-responsive" />
                    </div>


                    <div className="row interest">
                        <div id="interest" className="text col">
                            <h2>PHOTOSHOP & EDITS</h2>
                            <hr />
                            <p className="text interests-photoshop">
                                Messing around with images and creating collages is a fun and satisfying way to I like to express my creativity. Whether I'm combining photos of loved ones, creating a surreal landscape, or designing an abstract piece of art, there are endless possibilities for experimentation and play. With tools like Photoshop, I have learned how to manipulate images in countless ways, adding effects, adjusting colors, and creating new compositions. The process of creating a collage is also a great way to unwind and de-stress, as you get lost in the creative flow and lose track of time. I absolutely love the creative freedom that this artisitic medium has to offer.
                            </p>
                            {/* <button className="btn photography-gallery" onClick={routeChangePhotoshop}>View Gallery</button> */}
                        </div>
                        <img src="img/photoshop2.jpg" alt="Photoshop" className="col-6 img-fluid img-responsive" />

                    </div>

                    <div className="row interest">
                        <div id="interest" className="text col">
                            <h2>FRIENDS & FAMILY</h2>
                            <hr />
                            <p className="text interests-photoshop">
                                Friends and family are incredibly valuable in my life, providing me with love, support, and a sense of belonging. These are the people I turn to in times of need, and who help me celebrate my successes. This includes those who have offered me unconditional love, acceptance, and a listening ear when I needed it most. This sense of community and connectedness helps me navigate all the highs and lows of life. I am always excited to meet new people, expand my circle, and broaden my horizons. Having these strong social connections truly have such a positive impact on my life and are something I feel extremely lucky to experience.
                            </p>
                        </div>
                        <img src="img/family.JPG" alt="Mom and Me" className="col-6 img-fluid img-responsive" />
                    </div>
                </div>
            </div>
        </main>
    )
}