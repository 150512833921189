import { Routes, Route } from "react-router-dom";
import { Resume } from "./components/pages/Resume"
import { About } from "./components/pages/About"
import { Interests } from "./components/pages/Interests"
import { Gallery } from "./components/pages/Gallery";
import { Photoshop } from "./components/pages/Photoshop"
import { ScrollToTop } from "./components/ScrollToTop"


import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import './App.css';

export default function App(props) {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route exact path="/" element={<About />} />
        <Route path="/resume" Component={Resume} />
        <Route path="/interests" Component={Interests} />
        <Route path="/gallery" Component={Gallery} />
        <Route path="/photoshop" Component={Photoshop} />
      </Routes>
      <Footer />
    </div>
  );
}