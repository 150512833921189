import { React } from "react";

export function Gallery(props) {

    const handleClick = (event) => {
        console.log("you clicked me!");

    }
    return (
        <div>
            <main>
                <div className="boundary">
                    <div className="container gallery">
                        <div className="row gallery">
                            <div className="col gallery bg-image hover-zoom">
                                <img src="img/gallery/gallery60.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery12.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery17.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery21.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery24.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery3.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery49.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery46.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery58.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery53.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery48.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery30.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery8.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery31.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery55.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                            </div>
                            <div className="col gallery">
                                <img src="img/gallery/gallery20.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery22.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery14.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery41.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery13.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery26.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery10.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery4.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery23.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery43.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery16.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery57.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery40.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery56.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery52.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery1.jpg" alt="gallery Gallery" className="gallery" />
                            </div>
                            <div className="col gallery">
                                <img src="img/gallery/gallery2.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery61.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery7.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery6.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery29.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery63.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery15.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery42.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery34.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery51.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery45.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery54.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery18.jpg" alt="gallery Gallery" className="gallery" onClick={handleClick} />
                                <img src="img/gallery/gallery5.jpg" alt="gallery Gallery" className="gallery" />
                                <img src="img/gallery/gallery33.jpg" alt="gallery Gallery" className="gallery" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
