import React from "react";

export function About(props) {
    return (
        <main>
            <div className="boundary">
                <div className="row about">
                    <img src="img/malena-arduino2.jpg" alt="Malena Arduino" className="col-6 about-img img-fluid img-responsive" />

                    <div className="text col">
                        <h2>HELLO!</h2>
                        <hr />
                        <p className="about-text">
                        My name is Malena Arduino. I am a proactive professional with a robust background in leadership, management, development, and design. Guided by a holistic and user-centered philosophy, I skillfully employ a value-sensitive design approach to craft empathetic and innovative solutions. As a Hispanic woman holding a degree specializing in Human Computer Interaction and Data Science, I consistently infuse my work with a unique style and voice that transcend disciplines.
                        </p>

                        <p className="italic-text">
                            This website has been made using my knowledge of HTML, CSS, and React.
                        </p>

                        <a href="Malena-Arduino-Resume.pdf" download="Malena-Arduino-Resume.pdf">
                            <button className="btn btn-light">Download Resume</button>
                        </a>


                        <h2 id="lets-connect">LET&apos;S CONNECT!</h2>
                        <hr />
                        <div className="lets-connect">

                            <p className="regular-text">
                                Email:
                                <a className="connect" href="mailto: malenaarduino@hotmail.com">malenaarduino@hotmail.com</a>
                            </p>
                            <p className="regular-text">
                                Phone:
                                <a className="connect" href="tel:4256239622">4256239622</a>
                            </p>
                            <p className="regular-text">

                                LinkedIn:
                                <a className="connect" href="https://www.linkedin.com/in/malenaarduino/">linkedin.com/ln/malenaarduino</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}